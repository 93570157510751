<template>
    <v-container class="pt-5 px-0" fluid>
        <v-container class="content mb-6">
            <Headline text="media" />
            
            <v-row no-gutters>
                <v-container grid-list-xl>
                    <v-layout wrap justify-space-around>
                        <v-flex shrink v-for="(mediaFile, i) in $db.media.public" :key="i">
                            <Box-Media v-bind="mediaFile" />
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-row>
        </v-container>

        <Banner />

        <v-container class="content" id="99stalker">
            <v-row no-gutters justify="center" class="mb-8">
                <Box-Text title="99Stalker Browser Extension" text="Der 99Stalker ist eine von flashkill inspirierte, jedoch vollständig neu entwickelte Erweiterung für die 99Damage Liga. Durch die verbesserte technische Basis sind die abgerufenen Team-Informationen auf allen 99-Seiten verfügbar. Dadurch können diese auch auf Gruppen- und Matchseiten dargestellt werden. Zusätzlich wurden auch optische Anpassungen vorgenommen um z.B. auf Matchseiten Fremdkommentare hervorzuheben.
                " style="max-width: 870px" />
            </v-row>
            <v-row no-gutters justify="center" class="mb-6">
                <Box-Plugin title="Extension für Chrome" requirements="Chrome 73.0 or later" version="0.4" icon="chrome" :link="{ href: 'https://chrome.google.com/webstore/detail/99stalker/chnncbpnfaikifoibfgbgbjgphnpjcap?hl=de', target: '_blank' }" />
            </v-row>
            <v-row no-gutters justify="center">
                <Box-Plugin title="Extension für Firefox" requirements="Firefox 70.0 or later" version="0.4" icon="firefox" :link="{ href: 'https://addons.mozilla.org/de/firefox/addon/99stalker/', target: '_blank' }" />
            </v-row>
        </v-container>
        
    </v-container>
</template>

<script>
    import Banner from '@/objects/Banner.vue'
    import BoxText from '@/objects/Box-Text.vue'
    import BoxMedia from '@/objects/Box-Media.vue';
    import BoxPlugin from '@/objects/Box-Plugin.vue'

    export default {
        name: 'home',
        props: [ 'media-files' ],
        components: {
            Banner,
            BoxText,
            BoxMedia,
            BoxPlugin
        },
        data () {
            return {

            }
        },
        methods: {

        },
        mounted (){
            this.$db.media.loadPublic();
        }
    }
</script>

<style scoped>

</style>