<template>
    <v-flex class="box-plugin">
        <v-card class="px-6 py-4" tile flat>
            <v-row no-gutters>
                <v-col sm="2">
                    <v-card color="box_accent" height="100px" width="100px">
                        <v-row justify="center" align="center">
                            <v-col>
                                <v-img :src="require('@/assets/icon_' + icon + '.png')" width="70px" height="70px" color="box_accent" class="mx-auto" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col sm="8" class="pl-6">
                    <span class="text-h6 font-weight-regular accent--text">{{title}}</span>
                    <span class="text-caption">Requirements: {{requirements}}</span>
                    <a class="text-body-2 accent--text" href="https://addons.mozilla.org/de/firefox/addon/99stalker/versions/" target="_blank">Release notes</a>
                </v-col>
                <v-col sm="2">
                    <v-row no-gutters justify="center" class="mt-4">
                        <Button text="get it" :link="link" />
                        <span class="text-caption mt-2 text-center">version {{version}}</span>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>
    </v-flex>
</template>

<script>
//import Box from '@/objects/Box.vue';

export default {
    name: "box-plugin",
    props: [ 'title', 'requirements', 'version', 'icon', 'link' ],
    components: {
        //Box,
    }
}
</script>

<style scoped>
.box-plugin {
    max-width: 870px;
    /*
    padding: 20px 12px;
    min-width: 670px;
    */
}

.box-plugin .col span {
    display: block;
    width: 100%;
}
</style>