<template>
    <v-card class="box-media" tile flat width="375px">
        <v-row no-gutters>
            <span class="text-body-2 text-uppercase px-2 py-2">{{title}}</span>
        </v-row>
        <v-row no-gutters>
            <v-col cols="6">
                <axz-img :id="image" dummy="news_dummy.png" height="210px" />
            </v-col>
            <v-col cols="6" class="px-4 pt-6 pb-2">
                <span class="text-body-2 text-truncate">{{filename}}</span>
                <span class="text-body-2">Größe: {{filesize}}</span>
                <span class="text-body-2">Downloads: {{downloads}}</span>

                <v-row justify="center" class="mt-6">
                    <Button text="download" :link="file != null ? { href: $db.media.download(ID) } : null" :disabled="!file" />
                    <!--span v-else>File not found</span-->
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
//import Box from '@/objects/Box.vue';

export default {
    name: "box-media",
    props: [ 'ID', 'title', 'filename', 'filesize', 'downloads', 'image', 'file' ],
    data () {
        return {

        }
    },
    components: {
        //Box
    },
    computed: {

    }
}
</script>

<style scoped>
/* MEDIA BOX */
.box-media {
    /*width: 375px;
    height: 230px;*/
}

.box-media>div>span {
    /*height: 42px;*/
    /*line-height: 42px;*/
}
/*
.box-media>div>div .image{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
*/

.box-media .col>span {
    display: block;
    width: 100%;
}

.box-media .col:nth-child(1) {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.box-media .col:nth-child(2) {
    background-color: var(--color-site-font);
    color: var(--color-site);
}

.box-media .col:nth-child(2) span {
    border-bottom: rgba(0,0,0,0.2) solid;
    line-height: 32px;
}

/*
.box-media>div>div>div>span {
    
    
}*/


</style>